/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

// Import local components
import SubscribePage from "./SubscribePage";

// Reducer objects
import { CHANGE_SITE_INFO } from "../constants/actionTypes";

// Material UI components
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PrivacyPolicy from "./PrivacyPolicy";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    textDecoration: "underline",
  },
});

function SiteInfo({ stateSI, dispatchSI }) {
  const classes = useStyles();

  const handleClose = () => {
    dispatchSI({type: CHANGE_SITE_INFO, payload: {open: false}});
  };

  return (
    <div>
      <Dialog
        open={stateSI.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        {(stateSI.page == "about") &&
          <>
            <DialogTitle id="alert-dialog-title">About</DialogTitle>
            <DialogContent>
              <DialogContentText >
                <strong>Armalytics</strong> provides users with the ability to explore and track the public version of the Canadian Firearms Reference Table.
              </DialogContentText>
              <DialogContentText>
                Data is sourced from the <span className={classes.underline}>Firearms Reference Table (Public Version)</span> as authored by the RCMP and made available on their <a target="_blank" rel="noopener noreferrer" href="http://www.rcmp-grc.gc.ca/en/firearms/firearms-reference-table">website</a>. Updates to the RCMP document are usually reflected on <strong>armalytics.ca</strong> within 4hr and tagged with the <em>Valid As Of Date</em> property.
              </DialogContentText>
              <DialogContentText>
                <strong>Armalytics</strong> strives to provide the most accurate information possible however data extraction from PDFs is not perfect. That being said we estimate that 99.6% of the Firearm Reference Number records contained on the site are both accurate and complete.
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "contact") &&
          <>
            <DialogTitle id="alert-dialog-title">Contact</DialogTitle>
            <DialogContent>
              <DialogContentText>
                For any inquiries, or comments regarding our site please contact us at:
              </DialogContentText>
              <DialogContentText>
                <a href="mailto:info@armalytics.ca">info@armalytics.ca</a>
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "disclaimer") &&
          <>
            <DialogTitle id="alert-dialog-title">Disclaimer for public use</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The data displayed on the site is a non-commercial copy of an official work that is published by the Government of Canada and has not been produced in affiliation with, or with the endorsement of the Government of Canada.
              </DialogContentText>
              <DialogContentText >
                <em>&quot;The Firearms Reference Table (FRT) is not a legal instrument. The FRT is an administrative document created by the RCMP&apos;s firearms experts who have, based on criteria in the definitions found in section 84 of the Criminal Code and the supporting Regulations Prescribing Certain Firearms and other Weapons, Components and Parts of Weapons, Accessories, Cartridge Magazines, Ammunition and Projectiles as Prohibited or Restricted SOR/98-462, and the Firearms Act, conducted technical assessments of firearms to assist law enforcement officers, customs officers, and officials responsible for the regulation of firearms with the identification and classification of firearms. The aforementioned Acts and Regulations are the prevailing legal authority with respect to firearm classification.&quot;</em>
              </DialogContentText>
              <DialogContentText >
                <strong>Royal Canadian Mounted Police</strong>
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "terms") &&
          <>
            <DialogTitle id="alert-dialog-title">Terms &amp; Conditions</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <strong>Armalytics</strong> is a non-commercial project powered by the support of the firearms community in Canada.
              </DialogContentText>
              <DialogContentText>
                  All content available on this website is protected by copyright laws.  Unauthorized use, including but not limited to monetizing, reselling, or creating derivate works without explicit written permission from the copyright holder, is strictly prohibited.
                  By accessing this content, you agree to adhere to these terms and respect the intellectual property rights of the creators.
              </DialogContentText>
              <DialogContentText>
                <strong>Armalytics</strong> is strictly a data exploration tool and is not to be used for legal advice regarding the classification and regulation of firearms.
              </DialogContentText>
              <DialogContentText>
                Although we strive to present the most accurate information possible, <strong>Armalytics</strong> does not guarantee the accuracy of the content displayed on the site.
              </DialogContentText>
              <DialogContentText>
                By using this site you agree to the above terms &amp; conditions.
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "privacy") &&
          <PrivacyPolicy />
        }
        {(stateSI.page == "whySignUp") &&
          <>
            <DialogTitle id="alert-dialog-title">Why Sign Up?</DialogTitle>
            <DialogContent>
              <DialogContentText >
                Sign up to <strong>Armalytics</strong> and receive our newsletter which gives you:
              </DialogContentText>
              <DialogContentText>
                <ul>
                  <li>Alerts on <span className={classes.underline}>classification changes</span></li>
                  <li>Updates on any <span className={classes.underline}>major modifications</span> to critical FRT information such as Canadian Law Comments</li>
                  <li>Notifications regarding noteworthy or anticipated <span className={classes.underline}>firearm additions</span> to the FRT (eg. if the CZ Bren 2 Ms Carbine is added)</li>
                </ul>
              </DialogContentText>
              <DialogContentText >
                <strong>Stay informed and join our community today!</strong>
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "banMessage") &&
          <>
            <DialogTitle id="alert-dialog-title">Message on OICs</DialogTitle>
            <DialogContent>
              <DialogContentText >
                Firearms tagged with &quot;Affected by OIC #1&quot; or &quot;Affected by OIC #2&quot; have at least one or more configurations explicitly prohibited or interpreted by the RCMP to be prohibited under the May 1st, 2020 Order In Council or the December 5th, 2024 Order In Council, respectively.
              </DialogContentText>
              <DialogContentText >
              Please go to a firearm's <strong>Calibre, Shots, &amp; Barrel Length</strong> table to see detailed information on which configurations are prohibited and which ones are not.
              </DialogContentText>
              <DialogContentText >
              The &quot;Legal Authority&quot; in the afformentioned table gives you the legislation or regulation being used to justify the corresponding configuration's legal classification.
              </DialogContentText>
              <DialogContentText >
                For the official list of banned firearm models/variants/platforms please see the <a target="_blank" rel="noopener noreferrer" href="http://www.gazette.gc.ca/rp-pr/p2/2020/2020-05-01-x3/pdf/g2-154x3.pdf">May 1, 2020 edition</a> and <a target="_blank" rel="noopener noreferrer" href="https://gazette.gc.ca/rp-pr/p2/2024/2024-12-06-x3/html/sor-dors248-eng.html">Dec 5, 2024 edition</a> of the Canada Gazette.
              </DialogContentText>
            </DialogContent>
          </>
        }
        {(stateSI.page == "subscribe") &&
          <>
            <DialogTitle id="alert-dialog-title">Subscribe for Updates</DialogTitle>
            <DialogContent>
              <DialogContentText >
                Subscribe to <strong>Armalytics</strong> and receive alerts on :
              </DialogContentText>
              <DialogContentText>
                <ul>
                  <li><span className={classes.underline}>Classification changes</span></li>
                  <li>Updates on any <span className={classes.underline}>major modifications</span> to critical FRT information such as Canadian Law Comments</li>
                  <li>Notifications regarding noteworthy or anticipated <span className={classes.underline}>firearm additions</span> to the FRT</li>
                </ul>
              </DialogContentText>
              <DialogContentText>
                <SubscribePage />
              </DialogContentText>
            </DialogContent>
          </>
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SiteInfo.propTypes = {
  children: PropTypes.element,
  stateSI: PropTypes.object,
  dispatchSI: PropTypes.func,
};

export default SiteInfo;
