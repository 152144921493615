// Search query configurations
export default {
  alwaysSearchOnInitialLoad: true,
  initialState: {
    resultsPerPage: 10,
  },
  searchQuery: {
    search_fields: {
      model: {},
      make: {},
      manufacturer: {},
      action: {},
      type: {},
      legal_classification: {},
      country_of_manufacturer: {},
      calibres: {},
      importer: {},
      also_known_as_product_code: {},
    },
    result_fields: {
      frn: {
        raw: {}
      },
      valid_date: {
        raw: {}
      },
      model: {
        raw: {}
      },
      make: {
        raw: {}
      },
      manufacturer: {
        raw: {}
      },
      action: {
        raw: {}
      },
      type: {
        raw: {}
      },
      legal_classification: {
        raw: {}
      },
      level: {
        raw: {}
      },
      country_of_manufacturer: {
        raw: {}
      },
      serial_numbering: {
        raw: {}
      },
      calibre_shots_barrel_length: {
        raw: {}
      },
      notes: {
        raw: {}
      },
      cross_references: {
        raw: {}
      },
      also_known_as_product_code: {
        raw: {}
      },
      year_dates: {
        raw: {}
      },
      importer: {
        raw: {}
      },
      calibres: {
        raw: {}
      },
      banned: {
        raw: {}
      },
      banned_oic1: {
        raw: {}
      },
      banned_oic2: {
        raw: {}
      },
      oic_legal_authorities: {
        raw: {}
      },
      reclassified_date: {
        raw: {}
      },
      new_addition: {
        raw: {}
      },
    },
    disjunctiveFacets: ["new_addition", "reclassified_date", "oic_legal_authorities", "banned_oic1", "banned_oic2", "make", "type", "action", "legal_classification", "barrel_lengths", "calibres"],
    facets: {
      new_addition: { type: "value", size: 2 },
      banned_oic1: { type: "value", size: 3 },
      banned_oic2: { type: "value", size: 3 },
      oic_legal_authorities: { type: "value", size: 120},
      reclassified_date: { type: "value", size: 50},
      legal_classification: { type: "value", size: 5 },
      type: { type: "value", size: 30 },
      action: { type: "value", size: 30 },
      make: { type: "value", size: 250 },
      country_of_manufacturer: { type: "value", size: 200 },
      // valid_date: { type: "value", size: 4 },
      calibres: { type: "value", size: 200 },
      barrel_lengths: {
        type: "range",
        ranges: [
          { from: -1, name: "Any" },
          { from: 0, to: 105, name: "less than 106" },
          { from: 106, to: 305, name: "106 - 305" },
          { from: 306, to: 469, name: "306 - 469" },
          { from: 470, to: 610, name: "470 - 610" },
          { from: 611, to: 915, name: "611 - 915" },
          { from: 916, to: 1219, name: "916 - 1219" },
          { from: 1220, name: "greater than 1219" }
        ]
      }
    }
  },
  autocompleteQuery: {
    results: {
      resultsPerPage: 5,
      result_fields: {
        frn: { snippet: { size: 100, fallback: true } },
        model: { snippet: { size: 100, fallback: true } },
        url_field: {
          raw: {}
        }
      }
    },
    suggestions: {
      types: {
        documents: {
          fields: ["make", "legal_classification", "type", "action"]
        }
      },
      size: 4
    }
  },
  apiConnector: ''
};
